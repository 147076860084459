import { useForm } from "react-hook-form";
import { db } from "../config/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useState } from "react";

const suggestionsCollectionRef = collection(db, "suggestions");

const SuggestionFormFI= () => {
  const { register, handleSubmit, reset } = useForm();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (data) => {
    try {
      await addDoc(suggestionsCollectionRef, {
        ...data,
        createdAt: serverTimestamp(), // Store timestamp
      });
      setSubmitted(true);
      reset(); // Reset form after submission
    } catch (error) {
      console.error("Error submitting form: ", error);
    }
  };

  if (submitted) {
    return <div style={{ textAlign: "center", marginBottom: "10px" }}> <h5>Vastauksesi on tallennettu! Kiitos!</h5></div>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: "600px", margin: "0 auto" }}>
       <div style={{ textAlign: "center", marginBottom: "10px" }}> <h4>🚧 Slipper on suljettu remontin ajaksi 🚧</h4></div>
       
       <p>
  Slipperin ensimmäinen avoin testausvaihe on tullut päätökseensä. Haluamme kiittää ❤️ kaikkia osallistuneita. Kaikki palautteenne ja kokemuksenne tullaan huomioimaan uuden, upeamman Slipper-sovelluksen kehitystyössä! 🚀
</p>
<p>
  Uusi sovellus tulee edelleen toimimaan siltana digitaalisen ja todellisen maailman välillä, mutta tällä kertaa se yhdistää ihmiset automaagisesti myös{" "}
  <span style={{ color: "gold" }}>paikallisiin tapahtumiin ja aktiviteetteihin</span>. 
  </p>
  <p>
  Laajennamme sen {" "}
  <span style={{ color: "gold" }}>kaikille sopivaksi kohtaamisalustaksi</span> pelkän deittisovelluksen sijaan.
</p>
<p>
  Voit osallistua kehitystyöhön lähettämällä meille ideoita allaolevalla lomakkeella. Parhaat ideat palkitaan{" "}
  <span style={{ color: "gold" }}>Premium-jäsenyydellä</span>, joten kannattaa osallistua!
</p>

     
      <p>
        Seuraava avoin testivaihe alkaa{" "}
        <span style={styles.highlight}>Q2 2025</span>. Be ready!
      </p> 

      <label>Ehdota Slipperille uutta nimeä!</label>
      <input {...register("nameSuggestion")} type="text"  />

      <label>Mikä on itsellesi suurin syy miksi et käytä deitti- tai kohtaamissovelluksia?</label>
      <textarea {...register("reasonNotUsing")}  />

      <label>Jos voisit lisätä jonkin ominaisuuden kohtaamissovelluksiin, mikä se olisi?</label>
      <textarea {...register("reasonToUse")}  />

    
      <label>
        Annatko vielä sähköpostiosoitteesi? Emme spämmää, mutta saat tiedon kun uusi versio on käytettävissä.
      </label>
      <input {...register("email")} type="email" placeholder="Sähköpostiosoitteesi" />

      

      <button type="submit">Lähetä</button>

      <p>Saat meidät kiinni myös sivun alaosassa olevasta sähköpostiosoitteesta</p>
    </form>
  );


  
};


const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#121212",
    color: "#ffffff",
    textAlign: "center",
    padding: "50px",
  },
  logo: {
    maxWidth: "200px",
    marginBottom: "10px",
  },
  highlight: {
    color: "#ffcc00",
    fontWeight: "bold",
  },
  slogan: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: "#ffcc00",
    marginBottom: "20px",
  },
  email: {
    color: "#ffcc00",
    textDecoration: "none",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0", // Remove extra padding
    margin: "0", // Remove extra margin
  },
  teaserImage: {
    width: "100% !important", // Override any conflicting styles
    maxWidth: "none !important", // Ensure it doesn’t shrink
    height: "auto",
    borderRadius: "8px",
    display: "block",
  }
};

export default SuggestionFormFI;
