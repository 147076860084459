import { useState } from "react";
import SuggestionFormFI from "./SuggestionFormFI";
import SuggestionFormEN from "./SuggestionFormEN";

const LanguageSwitcher = () => {
  const [language, setLanguage] = useState("FI");

  return (
    <div style={{ margin: "20px" }}>
      {/* Centered language selector */}
      <div style={{ textAlign: "center", margin: "5px" }}>
        <span
          style={{
            cursor: "pointer",
            color: language === "FI" ? "gold" : "inherit",
            fontWeight: language === "FI" ? "bold" : "normal",
          }}
          onClick={() => setLanguage("FI")}
        >
          FI
        </span>{" "}
        |{" "}
        <span
          style={{
            cursor: "pointer",
            color: language === "EN" ? "gold" : "inherit",
            fontWeight: language === "EN" ? "bold" : "normal",
          }}
          onClick={() => setLanguage("EN")}
        >
          EN
        </span>
      </div>

      {/* Suggestion form (not centered) */}
      {language === "FI" ? <SuggestionFormFI /> : <SuggestionFormEN />}
    </div>
  );
};

export default LanguageSwitcher;
