import { useForm } from "react-hook-form";
import { db } from "../config/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useState } from "react";

const suggestionsCollectionRef = collection(db, "suggestions");

const SuggestionFormEN = () => {
  const { register, handleSubmit, reset } = useForm();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (data) => {
    try {
      await addDoc(suggestionsCollectionRef, {
        ...data,
        createdAt: serverTimestamp(), // Store timestamp
      });
      setSubmitted(true);
      reset(); // Reset form after submission
    } catch (error) {
      console.error("Error submitting form: ", error);
    }
  };

  if (submitted) {
    return <div style={{ textAlign: "center", marginBottom: "10px" }}> <h5>Your response has been saved! Thank you!</h5></div>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: "600px", margin: "0 auto" }}>
       <div style={{ textAlign: "center", marginBottom: "10px" }}> <h4>🚧 Slipper is closed for renovation 🚧</h4></div>
       
       <p>
        The first open testing phase of Slipper has come to an end. We want to thank ❤️ all the participants. All your feedback and experiences will be taken into account in the development of the new, even better Slipper app! 🚀
      </p>
      <p>
        The new app will still function as a bridge between the digital and real world, but this time it will also automatically connect people to{" "}
        <span style={{ color: "gold" }}>local events and activities</span>. 
      </p>
      <p>
        We are expanding it to be {" "}
        <span style={{ color: "gold" }}>a meeting platform for everyone</span> instead of just a dating app (as everyone hates 'em).
      </p>
      <p>
        You can contribute to the development by submitting your ideas using the form below. The best ideas will be rewarded with{" "}
        <span style={{ color: "gold" }}>Premium membership</span>, so make sure to participate!
      </p>

      <p>
        The next open testing phase will start{" "}
        <span style={styles.highlight}>Q2 2025</span>. Be ready!
      </p> 

      <label>Suggest a new name for Slipper!</label>
      <input {...register("nameSuggestion")} type="text"  />

      <label>What is the main reason you don't use dating or meeting apps?</label>
      <textarea {...register("reasonNotUsing")}  />

      <label>If you could add any feature to meeting apps, what would it be?</label>
      <textarea {...register("reasonToUse")}  />

      <label>
        Would you like to provide your email address? We won’t spam you, but you’ll get notified when a new version is available.
      </label>
      <input {...register("email")} type="email" placeholder="Your email address" />

      <button type="submit">Submit</button>

      <p>You can also contact us at the email address at the bottom of the page</p>
    </form>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#121212",
    color: "#ffffff",
    textAlign: "center",
    padding: "50px",
  },
  logo: {
    maxWidth: "200px",
    marginBottom: "10px",
  },
  highlight: {
    color: "#ffcc00",
    fontWeight: "bold",
  },
  slogan: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: "#ffcc00",
    marginBottom: "20px",
  },
  email: {
    color: "#ffcc00",
    textDecoration: "none",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0", // Remove extra padding
    margin: "0", // Remove extra margin
  },
  teaserImage: {
    width: "100% !important", // Override any conflicting styles
    maxWidth: "none !important", // Ensure it doesn’t shrink
    height: "auto",
    borderRadius: "8px",
    display: "block",
  }
};

export default SuggestionFormEN;
