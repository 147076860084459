import React from "react";
import slipperLogo from "../assets/SlipperTrans512.png";
import sortingSlipper from "../assets/markoworking.jpeg";
import LanguageSwitcher from "./LanguageSwitcher";

const SlipperTeaserLanding = () => {
  const obfuscateEmail = () => {
    const user = "slippertuki";
    const domain = "gmail.com";
    return `${user}@${domain}`;
  };

  return (
    <div>
      <div style={styles.container}>
        {/* <img src="placeholder-logo.png" alt="Slipper Logo" style={styles.logo} /> */}

        <img
          src={slipperLogo}
          alt="Slipper Logo"
          style={{ width: "50%", height: "auto" }}
        />
        <p style={styles.slogan}>Matchmaker for Moments</p>
      </div>
      <LanguageSwitcher />

      {/* Teaser Image */}
      <div style={styles.imageContainer}>
        <img
          src={sortingSlipper}
          alt="Slipper Teaser"
          style={styles.teaserImage}
        />
      </div>
      <br />

      <div style={styles.container}>
      <p>
       
        <a href={`mailto:${obfuscateEmail()}`} style={styles.email}>
          {obfuscateEmail()}
        </a>
      </p>
     
        <em>
          "I'm not looking for a fairytale. I'm looking for someone to watch
          Netflix with and eat junk food." 
        </em>
        <br/>
        <br/>
        <span>-Unknown</span>
     
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    /* backgroundColor: "#121212",
    color: "#ffffff", */
    textAlign: "center",
    padding: "50px",
  },
  logo: {
    maxWidth: "200px",
    marginBottom: "10px",
  },
  highlight: {
    color: "#ffcc00",
    fontWeight: "bold",
  },
  slogan: {
    fontSize: "1.2em",
    fontWeight: "bold",
    color: "#ffcc00",
    marginBottom: "20px",
  },
  email: {
    color: "#ffcc00",
    textDecoration: "none",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0", // Remove extra padding
    margin: "0", // Remove extra margin
  },
  teaserImage: {
    width: "100% !important", // Override any conflicting styles
    maxWidth: "none !important", // Ensure it doesn’t shrink
    height: "auto",
    borderRadius: "8px",
    display: "block",
  },
};

export default SlipperTeaserLanding;
